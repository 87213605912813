import * as React from "react"
import Layout from "../components/layout/en"


import { graphql, Link } from 'gatsby'
import Portfolio from "../components/home/portfolio"
import Clientes from "../components/commons/clientes"
import Testimonios from "../components/home/testimonios"
import { StaticImage } from "gatsby-plugin-image"

// markup
const NosotrosPage = (props) => {

  return (
    <Layout location={props.location} pageId="about" metaTitle="About us | Crequs">
        <section className="about-v1-page">
            <div className="about-agency-content">
                <div className="about-v-main">
                    <div className="about-v-title">
                        <h2><strong>Hello by Crequs 👋</strong> Agile and results-oriented web development</h2>
                    </div>
                </div>
                <div className="agency-banner-info">
                    <p>We are a multidisciplinary web development team with solid foundations in marketing, usability, user experience and programming.
                    If you need an eclectic and trustworthy <em>team</em> do not hesitate to <Link to="/en/contact-us"><a style={{color:'#dd2569'}}> contact us </a></Link>.</p>
                </div>
                <div className="row ag-teams">
					<div className="col-lg-3 col-md-4 col-sm-6">
						<div className="ag-team">
                            <StaticImage
                                src="../../images/team/paolo.jpg"
                                alt="Crequs"
                                width={200}
                                loading="eager"
                                backgroundColor="transparent"
                                placeholder="tracedSVG"
                                alt="Paolo Bergamelli"
                                quality="100"
                                className="team-img"
                            />
							<h3>Paolo Bergamelli</h3>
							<span>Front-end, Co-fundador</span>
						</div>
					</div>
					<div className="col-lg-3 col-md-4 col-sm-6">
						<div className="ag-team">
                            <StaticImage
                                src="../../images/team/ronald.jpg"
                                alt="Crequs"
                                width={200}
                                loading="eager"
                                backgroundColor="transparent"
                                placeholder="tracedSVG"
                                alt="Ronald Urquidi"
                                quality="100"
                                className="team-img"
                            />
							<h3>Ronald Urquidi</h3>
							<span>Back-end, Co-fundador</span>
						</div>
					</div>
					<div className="col-lg-3 col-md-4 col-sm-6">
						<div className="ag-team">
                            <StaticImage
                                src="../../images/team/mariann.jpg"
                                alt="Crequs"
                                width={200}
                                loading="eager"
                                backgroundColor="transparent"
                                placeholder="tracedSVG"
                                alt="Mariann Toth"
                                quality="100"
                                className="team-img"
                            />
							<h3>Mariann Toth</h3>
							<span>Marketing y Diseño, Co-fundadora</span>
						</div>
					</div>
				</div>
            </div>
            <style jsx global>{`
                .team-img{
                    border-radius:100%;
                    margin-bottom:30px;
                    filter: grayscale(100%);
                    -webkit-filter: grayscale(100%);
                }
            `}</style>
        </section>
        <Portfolio trabajos={props.data.allContentfulPortfolio.nodes} lang="en" />
        <Clientes clientes={props.data.allContentfulClientes.nodes} />
        <Testimonios testimonios={props.data.allContentfulTestimonios.nodes} />
    </Layout>
  )
}

export default NosotrosPage


export const pageQuery = graphql`
{
  allContentfulClientes(limit: 100, sort: {order: ASC, fields: createdAt}, filter:{node_locale:{eq:"en"}}) {
    nodes {
      title
      id
      logo {
        gatsbyImageData(layout: CONSTRAINED, width: 150, quality:100)
      }
    }
  }
  allContentfulPortfolio(filter: {node_locale: {eq: "en"}}) {
    nodes {
      id
      title
      web
      tieneFicha
      slug
      image{
				gatsbyImageData(layout: CONSTRAINED, width: 600, quality:60)
      }
      texto {
        raw
      }
      tipoDeTrabajo{
				id
        name
      }
      
    }
  }
  allContentfulTestimonios(sort: { fields: [createdAt], order: DESC }, filter: {node_locale: {eq: "en"}}){
    nodes {
      nombre
      cargo
      id
      imagen {
        gatsbyImageData(layout: FIXED, width: 70, quality: 70)
      }
      texto {
        raw
      }
    }
  }
}
`
